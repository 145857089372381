<template>
  <div>
    <v-row justify="center" class="bank-profile-container-row">
      <v-col cols="2" class="bank-profile-side-panel hidden-sm-and-down">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="11" md="7">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { uiHelper } from '@/util'
import { SESSION } from '@/constants/constants'
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'

import { AFFILIATE_PAYMENT_MEMBER_BANK_ACCOUNT, AFFILIATE_PAYMENT_BANK } from '@/store/affiliate.payment.module'
import { MEMBER_DETAIL } from '@/store/member.module'
export default {
  name: 'bankLayout',
  components: { AppBankAndProfileSidePanel },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {
    this.getMemberBankAccount()
    this.getBank()
    this.getMemberDetail()
  },
  methods: {
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getBank() {
      let obj = {
        bankName: '',
        bankCode: '',
        currency: uiHelper.getCurrency(),
        Page: '',
        ItemsPerpage: '',
        orderBy: '',
        order: ''
      }
      this.$store.dispatch(`${AFFILIATE_PAYMENT_BANK}`, { obj })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${AFFILIATE_PAYMENT_MEMBER_BANK_ACCOUNT}`, { obj })
    }
  }
}
</script>

<style lang="scss">
.bank-account-card {
  min-width: 350px;
  border: 2px solid var(--v-primary-base) !important;
  border-radius: 10px !important;
  &.inComplete {
    border: 2px solid var(--v-secondary_2-base) !important;
  }
}

.bank-balance {
  background-color: #121734;
  height: 36px;
  .v-divider {
    height: 100%;
    display: inline-block;
    border-color: red;
    border-right: 5px solid var(--v-navigator_active-base) !important;
  }
  .balance-amount {
    min-width: 100px;
  }
}

.bank-page-form {
  padding: 0px 20px 25px;
  max-width: 600px;
  margin: auto;
}

.payment-method-list-container {
  padding-bottom: 20px;
}
.payment-method-list-item {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  .payment-method-list-item-button {
    background-image: linear-gradient(to bottom, #273270, #1C244F);
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}

.bank-navigation-button {
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}

.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}

.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}
.deposit-page {
  min-height: 630px;
}

@media (max-width: 1500px) {
  .bank-profile-side-panel {
    min-width: 250px;
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }
  .bank-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }
  .payment-method-list-item .payment-method-list-item-button {
    padding: 12px !important;
    min-width: 120px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 959px) {
}
@media (max-width: 599px) {
  .bank-account-card{
    min-width: 250px;
  }
}
@media (max-width: 425px) {
}
</style>
